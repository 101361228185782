export const tbrData = [
  "A Black Women's History of the United States",
  "A Thousand Ships",
  "The Beautiful Ones",
  "Beautiful Star",
  "Blood like Magic",
  "Blood Scion",
  "Cemetery Boys",
  "Colonize This",
  "Daughters of Nri",
  "Gearbreakers",
  "Harlem Shuffle",
  "Kaikeyi",
  "Legendborn",
  "Lobizona",
  "Magic Steeped in Poison",
  "Malice",
  "The Mercies",
  "Noor",
  "Nubia the Awakening",
  "Open Water",
  "Pachinko",
  "S",
  "She Who Became the Sun",
  "Silver Under Nightfall",
  "The Startup Wife",
  "The Taking of Jake Livingston",
  "Tower",
  "What My Bones Know",
  "We Are Not Like Them"
];
